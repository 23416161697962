// Variables
@import "~src/master";

// Font icons
@import "~src/fontello";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config(
  $font-family: "Poppins",
);
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// Shades generator http://mcg.mbitson.com/
$md-primary: (
  50: #e5f5fb,
  100: #bde5f6,
  200: #91d4f0,
  300: #65c3ea,
  400: #44b6e6,
  500: #23a9e1,
  600: #1fa2dd,
  700: #1a98d9,
  800: #158fd5,
  900: #0c7ecd,
  A100: #f8fcff,
  A200: #c5e5ff,
  A400: #92cfff,
  A700: #79c3ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-accent: (
  50: #eff9fd,
  100: #d7eff9,
  200: #bce4f5,
  300: #a1d9f1,
  400: #8dd1ee,
  500: #79c9eb,
  600: #71c3e9,
  700: #66bce5,
  800: #5cb5e2,
  900: #49a9dd,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ceedff,
  A700: #b5e4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-warn: (
  50: #ffe7e6,
  100: #ffc4c1,
  200: #ff9d98,
  300: #ff766e,
  400: #ff584f,
  500: #ff3b30,
  600: #ff352b,
  700: #ff2d24,
  800: #ff261e,
  900: #ff1913,
  A100: #ffffff,
  A200: #fffafa,
  A400: #ffc8c7,
  A700: #ffafad,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-success: (
  50: #eafaec,
  100: #c9f4d1,
  200: #a6ecb2,
  300: #82e493,
  400: #67df7b,
  500: #4cd964,
  600: #45d55c,
  700: #3ccf52,
  800: #33ca48,
  900: #24c036,
  A100: #fcfffc,
  A200: #c9ffce,
  A400: #96ffa1,
  A700: #7cff8a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$web-primary: mat-palette($md-primary);
$web-accent: mat-palette($md-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat-palette($md-warn);

// Create the theme object (a Sass map containing all of the palettes).
$web-theme: mat-light-theme($web-primary, $web-accent, $web-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($web-theme);

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* You can add global styles to this file, and also import other style files */

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: none !important;
  border-color: #2563eb;
}

@font-face {
  font-family: "NotoSansLao";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansLao"), local("NotoSansLao-Regular"),
    url("fonts/NotoSansLao/NotoSansLao-Regular.ttf") format("woff2");
}
@font-face {
  font-family: "NotoSansLao";
  font-style: normal;
  font-weight: 300;
  src: local("NotoSansLao"), local("NotoSansLao-Light"),
    url("fonts/NotoSansLao/NotoSansLao-Light.ttf") format("woff2");
}
@font-face {
  font-family: "NotoSansLao";
  font-style: normal;
  font-weight: bold;
  src: local("NotoSansLao"), local("NotoSansLao-Bold"),
    url("fonts/NotoSansLao/NotoSansLao-Bold.ttf") format("woff2");
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: #fff;
  color: #121617;
}

body.la {
  font-family: "NotoSansLao", sans-serif !important;
}

a {
  color: $primaryColor;
}

img {
  max-width: 100%;
}
button {
  outline: none !important;
}
input {
  outline: none !important;
}

input.mat-input-element,
textarea.mat-input-element {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: $primaryColorFaded;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgba($primaryColor, 0.85);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryColor;
}

.language-switcher {
  font-size: 24px;
  font-weight: 500;
  max-width: 150px;

  .mat-form-field-underline {
    display: none;
  }
  .mat-select-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid;
    color: $primaryColor;

    margin-top: -2px;
  }
  .mat-select-value {
    padding-bottom: 2px;
  }
}

.sort-switcher {
  font-weight: normal;
  font-size: 18px;

  // max-width: 100px;

  .mat-form-field-infix {
    padding: 0;
    border: none;
    max-width: none;
    width: auto;
  }
  .mat-form-field-wrapper {
    padding: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-select-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid;
    color: $primaryColor;
    opacity: 0.6;
    border-radius: 20px;

    margin-top: -2px;
  }
  .mat-select-value {
    padding-bottom: 2px;
  }
  .mat-select-min-line {
    color: $primaryColor;
  }

  .mat-form-field {
    width: auto !important;
  }
  .mat-select-value {
    max-width: 100%;
    width: auto;
  }
  .mat-select-value {
    max-width: 100%;
    min-width: 100%;
  }

  &--long {
    max-width: 160px;
  }
  &--long2 {
    max-width: 360px;
  }
}

.mat-dialog-container {
  padding: 60px !important;
  border-radius: 48px !important;
}

.ty-checkbox {
  &.mat-checkbox {
    .mat-checkbox-frame,
    .mat-checkbox-background {
      border-radius: 5px !important;
    }

    .mat-checkbox-label {
      font-weight: 500;
      font-size: 21px;
    }
  }
}
.ty-field {
  &.mat-form-field {
    .mat-form-field-label {
      margin-left: 22px !important;
      margin-bottom: 2px;
    }
    .mat-input-element {
      padding-left: 20px !important;
    }
    .mat-form-field-infix {
      padding-right: 20px;
    }
    .mat-select-value {
      padding-left: 20px !important;
    }
    .mat-form-field-outline-start {
      border-radius: 18px 0 0 18px !important;
      min-width: 30px !important;
    }
    .mat-form-field-outline-end {
      border-radius: 0 18px 18px 0 !important;
    }
  }
}

.ty-button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600;
  font-size: 22px;
  border-radius: 18px !important;
}

.table-container {
  overflow: auto;
  // height: 600px;
  max-height: 600px;
  padding-right: 6px;
}
.table-wrap {
  position: relative;
  background: #f6fcff;
  .table {
    background: #f6fcff;
    width: 100%;

    thead {
      th {
        background: lighten($primaryColor, 40%);
        border-bottom: none;

        font-weight: 500;
        font-size: 16px;
        color: $primaryColor;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        &:first-child {
          border-radius: 14px 0 0 0;
        }
        &:last-child {
          border-radius: 0 14px 0 0;
        }
        &:not(:last-child) {
          border-right: 1px solid #dfdfdf;
        }
      }
    }
    tbody {
      padding-top: 10px;
      padding-bottom: 10px;
      td {
        border-bottom: none;

        font-weight: 500;
        font-size: 14px;
        text-align: left;
        color: #6d7071;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;

        &:not(:last-child) {
          border-right: 1px solid #dfdfdf;
        }
        &:not(:first-child):not(:last-child) {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
    }
  }
}

.table-pagination {
  position: sticky;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  background: #f6fcff;
  border-top: 1px solid #dfdfdf;
  border-radius: 0 0 14px 14px;

  &__list {
    display: flex;
  }

  &__prev-wrap {
    margin-right: 12px;
  }
  &__next-wrap {
  }

  &__prev,
  &__next {
    font-weight: normal;
    font-size: 18px;
    color: $primaryColor;
  }

  &__list-wrap {
    margin-right: 12px;
  }
  &__list {
    &-item {
      &:not(:last-child) {
        margin-right: 24px;
      }

      &-btn {
        border-radius: 7px;
        // padding: 4px 12px !important;
        min-width: 38px;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        color: $greyAccent;

        &.active {
          background: $primaryColor;
          color: #fff;
        }
      }
    }
  }
}

.widget {
  border-radius: 14px;
  background: $primaryColorFaded;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  // height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 600;
    font-size: 21px;
  }

  &__content {
    height: 100%;
    overflow: auto;
  }

  &__list {
    padding-right: 10px;
    &-item {
      display: flex;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &-image-wrap {
        flex: 0 0 auto;
        width: 55px;
        height: 55px;
        margin-right: 12px;
      }
      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;

        &--accent {
          width: 60px;
          object-fit: cover;
          background: $primaryColorFaded;
          padding: 5px 11px;
          border-radius: 12px;
          image-rendering: -webkit-optimize-contrast;
        }
      }
      &-content {
        width: 100%;
        padding-right: 30px;
      }
      &-title {
        font-weight: 600;
        font-size: 18px;
        color: #121617;
        // margin-bottom: 4px;
      }
      &-date {
        font-weight: normal;
        font-size: 10px;
        color: #121617;
        margin-bottom: 4px;
      }
      &-subtitle {
        margin-top: 6px;
        font-weight: normal;
        font-size: 14px;
        color: $greyAccent;
      }
      &-controls {
        position: absolute;
        right: 0;
        top: 5px;
      }
      &-control-btn {
        i {
          font-size: 15px;
          color: $greyAccent;
        }
      }
      &-value {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        word-break: break-all;
      }
    }
  }

  &__row-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &-item {
      flex: 0 0 auto;
      margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: 12px;
      }

      &-image {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;

        margin-bottom: 10px;
      }
      &-title {
        font-weight: 500;
        font-size: 12px;
        color: #121617;
        text-align: center;
      }
    }
  }
}
.tags {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 14.5px;
  color: #fff;

  &.statuss-pending {
    background-color: $yellowColor;
  }
  &.statuss-cancelled {
    background-color: $greyColor;
  }
  &.statuss-used {
    background-color: $greenColor;
  }
  &.statuss-expired {
    background-color: $redColor;
  }
}

.tag {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 14.5px;
  background: #000;
  color: #fff;

  &.statuss-pending {
    color: $yellowColor;
  }
  &.statuss-cancelled {
    color: $redColor;
  }
  &.statuss-used {
    color: $greenColor;
  }

  &.status-active,
  &.status-complete,
  &.status-online,
  &.status-paid {
    background: $greenColor;
    i {
      color: $greenColor;
    }
  }
  &.status-pending {
    background: $yellowColor;
    i {
      color: $yellowColor;
    }
  }
  &.status-in-progress,
  &.status-in_progress {
    background: $purpleColor;
    i {
      color: $purpleColor;
    }
  }
  &.status-closed,
  &.status-canceled,
  &.status-suspended {
    background: $redColor;
    i {
      color: $redColor;
    }
  }
  &.status-offline {
    background: $greyColor;
    i {
      color: $greyColor;
    }
  }
  &.status-delivered {
    background: $blueColor;
    i {
      color: $blueColor;
    }
  }

  &.tag-primary {
    background: $primaryColor;
    i {
      color: $primaryColor;
    }
  }

  &.t-active {
    background: $yellowColor;
    i {
      color: $yellowColor;
    }
  }
  &.t-refund,
  &.t-refunded {
    background: $purpleColor;
    i {
      color: $purpleColor;
    }
  }
  &.t-paid {
    background: $greenColor;
    i {
      color: $greenColor;
    }
  }
  &.t-in_progress {
    background: $greyColor;
    i {
      color: $greyColor;
    }
  }
  &.t-cancel {
    background: $redColor;
    i {
      color: $redColor;
    }
  }
  &.t-pending {
    background: $blueColor;
    i {
      color: $blueColor;
    }
  }
  i {
    margin-right: 6px;
    font-size: 10px;
    background: #fff;
    border-radius: 50%;
    padding: 2px 5px;
    color: #000;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
  }
}

.ngx-charts {
  width: 100%;
  height: 100%;
}

.dialog-header {
  display: flex;
  margin-bottom: 30px;
}
.dialog-title {
  font-weight: 600;
  font-size: 34px;
  color: #121617;
}
.dialog-subtitle {
  font-weight: 600;
  font-size: 26px;
  color: #121617;
}
.dialog-close-btn {
  margin-left: auto;

  padding: 12px 15px;
  border-radius: 15px;
  background: rgba(255, 120, 120, 0.1);
  color: #ff7878;
}
.dialog-submit-btn,
.dialog-remove-btn {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.dialog-remove-btn {
  border-color: $redColor;
}
.dialog-upload-prefield {
  position: relative;
  &__btn {
    position: absolute !important;
    top: 3px;
    right: 0;
    background: $primaryColor;
    color: #fff !important;
    border-radius: 18px !important;
    font-size: 20px;
    width: 80px;
    height: calc(100% - 1.34375em);
  }
}
.dialog-section-title {
  font-weight: 600;
  font-size: 24px;
  color: $primaryColor;
  margin-bottom: 24px;
}

.snackbar-error {
  background: $redColor;
  color: #fff;
}

// .cdk-overlay-pane {
//   height: 100%;
// }
// .mat-dialog-container {
//   height: calc(100% - 20px);
//   margin: auto;
// }

@media (max-width: 1640px) {
  .widget {
    &__row-list {
      &-item {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .widget {
    &__row-list {
      &-item {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (max-width: 1360px) {
  .table-container {
    height: auto;
    max-height: none;
    overflow: unset;
    padding-right: 6px;
  }
  .table-pagination {
    position: static;
  }
  .table-wrap {
    overflow: auto;
    .table {
      width: 1199px;
      thead {
        th {
          font-weight: 500;
          font-size: 12px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      tbody {
        td {
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  .mat-dialog-container {
    padding: 30px !important;
  }
}
@media (max-width: 576px) {
  .cdk-global-overlay-wrapper {
    padding: 0 15px !important;
  }
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
  .table-wrap {
    .table {
      width: 600px;
    }
  }
  .table-pagination {
    padding: 20px 15px;

    &__list {
      display: flex;
    }

    &__list-wrap {
      display: none;
    }
  }

  .dialog-header {
    display: flex;
    margin-bottom: 20px;
  }
  .dialog-title {
    font-size: 24px;
  }
  .dialog-close-btn {
    padding: 6px 9px;
  }
  .dialog-submit-btn,
  .dialog-remove-btn {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .dialog-section-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
