$primaryColor: #23a9e1;
$primaryColorFaded: rgba($primaryColor, 0.075);
$accentColor: #79c9eb;
$textColor: #121617;
$greyAccent: #939c9e;

$blueColor: #23a9e1;
$greenColor: #29d58b;
$yellowColor: #f9c03b;
$redColor: #ff7878;
$purpleColor: #956ce8;
$greyColor: #939c9e;
